import React from "react"
import { Navbar } from "./Styled/Navbar"
import { GlobalStyles } from "./GlobalStyles"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div data-is-root-path={isRootPath}>
      <GlobalStyles />
      <Navbar
        logo="Brighter Finance"
        links={[
          {
            name: "Home",
            link: "/",
          },
          {
            name: "About",
            link: "/about",
          },
          {
            name: "Schedule a Meeting",
            link: "/contact",
          },
        ]}
      />
      {/* <header className="global-header">{header}</header> */}
      <main>{children}</main>
      {/* <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer> */}
    </div>
  )
}

export default Layout
