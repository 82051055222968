import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Roboto', sans-serif;
    /* font-size: calc(16px + (26 - 14) * ((100vw - 300px) / (1600 - 300))); */
}
`
